@import "./assets/css/indigo-pink.css";

body {
  margin: 0;
  font-family: "Arial", Helvetica, sans-serif;
  font-size: 14px;
  height: 100vh !important;
}


.full-width {
  width: 100%;
}

.container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.spinner {
  left: 49%;
  position: fixed !important;
  top: 15%;
  z-index: 9999;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.login-container {
  height: 80vh;
}

.login-actions {
  margin-left: unset !important;
}

.mat-list-item,
.mat-menu-item {
  font-size: 14px !important;
}

.command-wrapper button {
  margin-left: 10px;
  margin-bottom: 5px;
}

.mat-dialog-title {
  margin: 0 !important;
}

.mat-column-actions {
  flex: 0 0 60px;
}

.mat-table .mat-row:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.mat-table td.mat-cell a:hover {
  text-decoration: underline;
}

.mat-table td.mat-cell:first-child,
.mat-table td.mat-footer-cell:first-child,
.mat-table th.mat-header-cell:first-child {
  padding-left: 20px !important;
}

.mat-table td.mat-cell:last-child,
.mat-table td.mat-footer-cell:last-child,
.mat-table th.mat-header-cell:last-child {
  padding-right: 10px !important;
}

.mat-table .mat-cell a {
  color: inherit;
  text-decoration: none;
}

.no-records {
  padding-top: 20px;
  padding-bottom: 20px;
}

.refresh-button {
  margin-top: 8px !important;
}

.no-records .mat-icon {
  width: 130px;
  height: 130px;
  font-size: 130px;
  color: #c7c7c7;
}

p .mat-icon {
  vertical-align: middle;
}

.mat-slide-toggle.full-width {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mat-tooltip {
  font-size: 14px;
}

.fixed-fab {
  position: fixed !important;
  right: 2%;
  bottom: 3%;
}

/* Highlight on hover */
.submitted-timesheet-day:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
/* Highlight */
.submitted-timesheet-day>div:not(.mat-calendar-body-selected) {
  border-radius: 999px;
  background-color: rgba(14, 152, 5, 0.25);
}

.table-wrapper {
  overflow: auto;
  height: calc(100vh - 270px);
  max-height: 450px;
  /* padding: 10px 5px; */
}

.animate {
  animation-name: show;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0 16px 0 0 !important;
}

.menu-sidebar .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

.menu-sidebar .mat-expansion-panel-body {
  padding: 0 !important;
}

.menu-sidebar .mat-expansion-panel-header {
  padding: 0 24px 0 10px !important;
}

.menu-sidebar .mat-expansion-panel-spacing {
  margin: 0 !important;
}

.menu-sidebar .mat-expansion-panel-header.mat-expanded {
  height: 45px !important ;
}

.menu-sidebar .mat-list-base .mat-list-item {
  height: 40px !important ;
}

.menu-sidebar .mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.legend-title-text {
  display: none !important;
}


/* Plantilla Global */

.card-title {
  float: left;
  font-size: 1.1rem;
  color: #0061A2;
  font-weight: bold;
  margin: 0;
}

.card-title-no-cursor {
  float: left;
  font-size: 1.1rem;
  color: #0061A2;
  font-weight: bold;
  margin: 0;
}

.card-subtitle {
  float: left;
  font-size: 0.9rem;
  color: #878585;
  font-weight: 400;
  margin: 10px 0 0 0;
}
.card-subtitleDate {
  float: left;
  font-size: 0.6rem;
  color: #878585;
  font-weight: 400;
  margin: 10px 0 0 0;
}

.labelsValue {
  float: right;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.titleValue {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 15px;
}

.mat-card-actions, .mat-card-subtitle, .mat-card-content.cardValue{
  margin-bottom: 30px !important;
}

.table {
  width: 100%;
  margin: 20px 0;
  border-spacing: 0;
}

.table thead tr{
  text-align: left;
  background-color: rgb(3, 97, 162);
  color: #fff;
}

.table thead tr th{
  padding: 15px 10px;
}

.table thead tr th.center{
  text-align: center;
}

.table tbody tr td {
  padding: 15px 10px;
  width: 10%;
}

.table tbody tr td.center{
  text-align: center;
}

.table tbody tr td.title{
  font-weight: bold;
}

.table tbody tr:nth-child(even) {
  background-color: rgb(242, 247, 250)
}

.table tbody tr:nth-child(odd) {
  background-color: rgb(204, 223, 236)
}

.table tbody tr:nth-child(even):hover {
  background-color: rgba(12, 94, 155, 0.6)
}

.table tbody tr:nth-child(odd):hover {
  background-color: rgba(12, 94, 155, 0.6)
}

.table tbody tr.link{
  cursor: pointer;
}



